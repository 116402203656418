import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import {
  PublicClientApplication,
  EventType,
  LogLevel,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import './assets/styles/slick/slick.css'
import './assets/styles/slick/slick-theme.css'
import './assets/styles/common.css'
import LoadingIconComponent from './components/Layout/UIComponent/LoadingIconComponent'
import ErrorBoundary from './components/ErrorBoundry'
import ScrollToTop from './components/Layout/UIComponent/ScrollToTop'
import withHostCheck from './utils/withHostCheck'
import { getStorageData } from './utils/helper'

const Theme = lazy(() => import('./theme'))
const Login = lazy(() => import('./containers/LogIn/index'))
const ResetPassword = lazy(
  () => import('./containers/ResetPasswordContainer/index'),
)
const ForceLogin = lazy(() => import('./containers/ForceLogIn/index'))
const TvpLogin = lazy(() => import('./containers/TvpLogin/index'))
const MtcLogin = lazy(() => import('./containers/MtcLogIn/index'))

const UsersVerificationContainer = lazy(
  () => import('./containers/UsersVerificationContainer/index'),
)
const SelfOrgRegistration = lazy(
  () => import('./containers/SelfOrgRegistration/index'),
)
const SpIntegrationPage = lazy(
  () => import('./pages/SpIntegrationcontainerPage'),
)
const SignpostingPage = lazy(() => import('./pages/SignpostingPage'))
const OrgSelection = lazy(() => import('./components/OrganisationSelection'))
const SurveyQuestionContainer = lazy(
  () => import('./containers/TeamAdminSetting/SurveyQuestionContainer'),
)
const QuickDecisionMakerPage = lazy(
  () => import('./pages/QuickDecisionMakerPage'),
)

const CheckOutFeedbackPage = lazy(() => import('./pages/CheckOutFeedbackPage'))

const referenceFeedback = lazy(() => import('./pages/ReferenceFeedbackPage'))

const administerUserSignPage = lazy(
  () => import('./pages/administerUserSignPage'),
)

const LinkExpiredContainer = lazy(
  () => import('./containers/LinkExpiredContainer/index'),
)

const userSelfBookingPage = lazy(() => import('./pages/UserSelfBookingPage'))
const customFormLink = lazy(
  () => import('./pages/CustomFormLinkPage/CustomFormLink'),
)

const PublicFormPage = lazy(() => import('./pages/PublicFormPage/index'))
const App = () => {
  const msalCred = getStorageData('msal')

  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */

  const manageRedirect = (tennet) => {
    switch (tennet) {
      case '23de4379-957a-41a6-9587-165d6c6b4dbd':
        return '/TVPCC-Logon'

      case 'f31b07f0-9cf9-40db-964d-6ff986a97e3d':
        return '/kforce-login'

      default:
        return '/mtc-login'
    }
  }

  const msalConfig = msalCred && {
    auth: {
      clientId: msalCred.client_id, // This is the ONLY mandatory field that you need to supply.
      authority: `https://login.microsoftonline.com/${msalCred.tenant_id}`, // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
      postLogoutRedirectUri: manageRedirect(msalCred.tenant_id), // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
        },
      },
    },
  }

  const msalInstance = msalCred ? new PublicClientApplication(msalConfig) : null

  // Default to using the first account if no account is active on page load

  // Listen for sign-in event and set active account

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Router>
          <ScrollToTop />
          <ErrorBoundary>
            <Suspense fallback={<LoadingIconComponent heyLoader />}>
              <Switch>
                <Route path="/kforce-login" render={() => <ForceLogin />} />
                <Route path="/Kyouth-login" render={() => <ForceLogin />} />
                <Route path="/TVPCC-Logon" render={() => <TvpLogin />} />
                <Route path="/mtc-login" render={() => <MtcLogin />} />
                <Route path="/login" component={Login} />
                <Route
                  path="/reset-password/:token/:email"
                  component={ResetPassword}
                />
                <Route
                  path="/survey/question/:survey_id"
                  component={SurveyQuestionContainer}
                />
                <Route
                  path="/self-org-registration"
                  component={SelfOrgRegistration}
                />
                <Route path="/orgselection" component={OrgSelection} />
                <Route
                  path="/quick-decision-maker"
                  component={QuickDecisionMakerPage}
                />
                <Route path="/sp-integration" component={SpIntegrationPage} />
                <Route path="/signposting" component={SignpostingPage} />
                <Route
                  path="/users-verification"
                  component={UsersVerificationContainer}
                />
                <Route
                  path="/reference-feedback"
                  component={referenceFeedback}
                />
                <Route
                  path="/checkout-feedback/:status/:suId/:childId"
                  component={CheckOutFeedbackPage}
                />
                <Route
                  path="/user-administer/"
                  component={administerUserSignPage}
                />
                <Route
                  path="/user-self-booking/"
                  component={userSelfBookingPage}
                />
                <Route
                  path="/link-expired-email/"
                  component={LinkExpiredContainer}
                />
                <Route path="/custom-form-link/" component={customFormLink} />
                <Route path="/public-form/" component={PublicFormPage} />
                <Route path="/" component={Theme} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Router>
      </MsalProvider>
    </>
  )
}

export default withHostCheck(App)
