export const environment = { dev: 'development', prod: 'production' }

export const userRole = {
  ADMIN: '1',
  RO: '2',
  SUPERVISOR: '3',
  SU: '4',
  SUPER_ADMIN: '5',
  FAMILY_OR_FRIEND: '6',
  MENTOR: '7',
  READ_ONLY: '8',
  INTERVENTIONS_COORDINATOR: '9',
  ORG: '10',
  TEAM_ADMIN: '12',
}

export const TablePageSize_5 = 5
export const TablePageSize_10 = 10
export const TablePageSize_50 = 50
export const DropDownPageSize = 25
export let TablePageSize = 25
export let TablePageData
export const setTablePageData = (data) => {
  sessionStorage.setItem('page_limit', JSON.stringify(data))
  TablePageSize = data ? data.page : 25
  TablePageData = data
}
export const DefaultPage = 1
export const DefaultStatus = 1
export const SearchWordLength = 3
export const SearchWordLengthOne = 1
export const actionColumn = 'Are you sure you want to change the status'

export const permissions = {
  role: 1,
  om: 2,
  serviceusers: 3,
  organisation: 4,
  intervention: 5,
  allevent: 6,
  calendar: 7,
  notification: 8,
  checkin: 9,
  chat: 10,
  suallocation: 11,
  supervisor: 12,
  issue: 13,
  reschedule: 14,
  feedback: 15,
  announcement: 16,
  health: 17,
  events: 19,
  viewAllForceItems: 20,
  SeeAuditTrail: 21,
  ManuallyApproveAnyItem: 22,
  ReopenClosedItems: 23,
  AbleToSelectServiceProvider: 24,
  scrutiny: 25,
  documentation: 26,
  mark_as_complete: 27,
  general_settings: 28,
  user_settings: 29,
  service_users_tabs: 30,
  su_bio_tabs: 31,
  manage_flag: 32,
  manage_question: 33,
  manage_assessment_tab: 34,
  manage_checkout: 35,
  manage_custom_tabs: 36,
  manage_tabs: 37,
  manage_portal_variable: 38,
  manage_practitioners_fields: 39,
  manage_notification: 40,
  manage_eligibility: 41,
  announcement_setting: 42,
  manage_document_template: 43,
  import: 44,
  survey: 45,
  sms: 46,
  panel: 48,
  referrals: 47,
  print_q: 49,
  video_call: 50,
  service_partner: 51,
  help_wiki: 52,
  summary_tab: 53,
  event_tab: 54,
  team_tab: 55,
  user_tab: 56,
  condition_tab: 57,
  reminder_tab: 58,
  override_tab: 59,
  progress_tab: 60,
  note_and_appmts_tab: 61,
  inquiry_tab: 62,
  scrutiny_tab: 63,
  panel_tab: 64,
  note: 65,
  condition: 67,
  form_approver: 68,
}

export const incidentUserType = {
  SUSPECT: 1,
  VICTIM: 2,
  WITNESS: 3,
}

export const integrationType = {
  niche: 1,
}

export const integrationTypeArray = [
  {
    value: 1,
    label: 'Niche',
  },
]

export const type_document_data = {
  event: 1,
  intervention: 2,
  Appointment: 3,
  note: 4,
  su: 5,
}

export const referenceColumnOptions = [
  {
    value: 1,
    title: 'Free Text with +',
  },
  {
    value: 3,
    title: 'Free Text',
  },
  {
    value: 2,
    title: 'DROP DOWN',
  },
  {
    value: 4,
    title: 'DATE',
  },
]

export const userPortalVariableOptions = [
  {
    value: 1,
    title: 'Free Text',
  },
  {
    value: 2,
    title: 'DROP DOWN',
  },
  {
    value: 4,
    title: 'DATE',
  },
]

export const referenceColumnValue = {
  free_text_plus: 1,
  drop_down: 2,
  free_text: 3,
  date: 4,
}
export const incidentReferenceColumnValue = {
  free_text_plus: 1,
  drop_down: 2,
  free_text: 3,
  date: 4,
}

export const suTitleOption = [
  { value: 'Mr', title: 'Mr' },
  { value: 'Mrs', title: 'Mrs' },
  { value: 'Ms', title: 'Ms' },
  { value: 'Miss', title: 'Miss' },
  { value: 'Mx', title: 'Mx' },
  { value: 'Dr', title: 'Dr' },
  { value: 'ACC', title: 'ACC' },
  { value: 'Admiral', title: 'Admiral' },
  { value: 'Colonel', title: 'Colonel' },
  { value: 'Commander', title: 'Commander' },
  { value: 'General', title: 'General' },
  { value: 'Lt', title: 'Lt' },
  { value: 'Lt Colonel', title: 'Lt Colonel' },
  { value: 'Major', title: 'Major' },
  { value: 'Sqd Leader', title: 'Sqd Leader' },
  { value: 'Captain', title: 'Captain' },
  { value: 'Dame', title: 'Dame' },
  { value: 'Rt Hon', title: 'Rt Hon' },
  { value: 'DO', title: 'DO' },
  { value: 'C/Supt', title: 'C/Supt' },
  { value: 'C/Insp', title: 'C/Insp' },
  { value: 'Chief Constable', title: 'Chief Constable' },
  { value: 'D.Supt', title: 'D.Supt' },
  { value: 'DC', title: 'DC' },
  { value: 'DCC', title: 'DCC' },
  { value: 'DCL', title: 'DCL' },
  { value: 'DI', title: 'DI' },
  { value: 'DS', title: 'DS' },
  { value: 'Insp', title: 'Insp' },
  { value: 'Lady', title: 'Lady' },
  { value: 'Lord', title: 'Lord' },
  { value: 'PC', title: 'PC' },
  { value: 'PS', title: 'PS' },
  { value: 'Rear Admiral', title: 'Rear Admiral' },
  { value: 'SC', title: 'SC' },
  { value: 'Sister', title: 'Sister' },
  { value: 'Supt', title: 'Supt' },
  { value: 'PCSO', title: 'PCSO' },
  { value: 'Father', title: 'Father' },
  { value: 'Professor', title: 'Professor' },
  { value: 'Rev', title: 'Rev' },
  { value: 'Sir', title: 'Sir' },
  { value: 'Other', title: 'Other' },
]

export const columnType = {
  incident_columns: {
    summary: 1,
    incident: 2,
    team: 3,
    user: 4,
    condition: 5,
    reminder: 6,
    override: 7,
    progress: 8,
    note_app: 9,
    inquiry: 10,
    scrutiny: 11,
  },
  sp_columns: {
    condition: 1,
    referrals: 2,
    service_users: 3,
  },
  sp_dashboard_condition_filter: {
    columns_name: 1,
  },
  sp_dashboard_referrals_filter: {
    columns_name: 1,
  },
  sp_dashboard_clinic_filter: {
    columns_name_1: 1,
    columns_name_2: 2,
    columns_name_3: 3,
  },
}

export const importIncidentStatus = [
  'Enhanced - Action Fraud',
  'Non Enhanced - Action Fraud',
  'Enhanced',
  'Enhanced DA',
  'Enhanced DA - REVIEW',
  'Review',
  'Non-Enhanced',
  'Non-Enhanced DA',
]

export const importType = {
  actionFraud: '1',
  niche: '2',
  incidentCustomField: '3',
  userCustomField: '4',
  note: '5',
  document: '6',
}

export const viewableFileExt = [
  'jpeg',
  'JPEG',
  'Jpeg',
  'png',
  'PNG',
  'Png',
  'jpg',
  'JPG',
  'Jpg',
  'pdf',
]

export const statusFilter = [
  {
    text: 'Active',
    value: '1',
  },
  {
    text: 'Inactive',
    value: '0',
  },
  {
    text: 'All',
    value: ' ',
  },
]
